<template>
  <div class="user-watch-rules__page">
    <div
      v-if="fetching"
      class="user-watch-rules--loading">
      <v-progress-circular
        indeterminate
        size="100"
        color="secondary"/>
    </div>
    <div
      v-else-if="watchRules.length"
      class="user-watch-rules">
      <h3
        class="page-title"
        v-text="$t('page.title')"/>
      <p
        class="page-description"
        v-text="$t('page.description')"/>
      <v-data-table
        v-model="selectedRows"
        :items="filteredItems"
        :headers="$static.tableHeaders"
        checkbox-color="secondary"
        disable-pagination
        show-select
        hide-default-footer>
        <template #top>
          <v-toolbar
            height="96"
            flat>
            <div>
              <CySearchBox
                v-model.trim="searchTerm"
                aria-label="Search items"
                :placeholder="$t('searchPlaceholder')"
                append-icon="search"
                clearable
                class="search-field"/>
              <h4
                class="user-watch-rules__count"
                v-html="$sanitizeHtml(itemCountText)"/>
            </div>
            <v-spacer/>
            <CyButton
              v-if="selectedRows.length"
              theme="error"
              icon="delete"
              @click="showDeleteDialog = true">
              {{ $tc('deleteNWatchRules', selectedRows.length, { n: selectedRows.length }) }}
            </CyButton>
            <CyButton
              v-else
              theme="secondary"
              icon="add"
              @click="createWatchRule">
              {{ $t('createWatchRule') }}
            </CyButton>
          </v-toolbar>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <CyAvatar
              :item="{
                icon: item.project_canonical ? 'commit' : 'podcasts',
                color: item.project_canonical ? 'prod' : 'staging',
              }"
              class="mr-2"
              sm/>
            <strong>
              {{ item.name }}
            </strong>
          </div>
        </template>
        <template #[`item.muted`]="{ item }">
          <CyTag
            v-if="item.muted"
            variant="default"
            icon-before="volume_off">
            {{ $t('muted') }}
          </CyTag>
        </template>
        <template #[`item.project_canonical`]="{ item }">
          <span
            v-if="item.project_canonical"
            class="cy-link"
            @click="goToProject(item.project_canonical)">
            <v-icon class="mr-1">
              folder_open
            </v-icon>
            {{ item.project_canonical }}
          </span>
        </template>
        <template #[`item.organization_canonical`]="{ item }">
          <div class="d-flex align-center">
            <CyAvatar
              :item="getOrgByCanonical(item.organization_canonical)"
              class="mr-2"
              sm/>
            {{ getOrgByCanonical(item.organization_canonical).name }}
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <CyMenu
            :items="getActionMenuItems(item)"
            :min-width="280"
            bottom
            offset-y
            left>
            <template #activator="{ on }">
              <CyButton
                aria-label="Actions menu"
                variant="tertiary"
                theme="primary"
                icon="more_horiz"
                max-width="24"
                max-height="24"
                icon-only
                sm
                v-on="on"
                @click.stop/>
            </template>
          </CyMenu>
        </template>
      </v-data-table>
    </div>
    <div
      v-else
      class="user-watch-rules--empty">
      <v-icon>
        visibility
      </v-icon>
      <div
        class="empty-watch-rules__title"
        v-text="$t('emptyWatchRules.title')"/>
      <div
        class="empty-watch-rules__text"
        v-text="$t('emptyWatchRules.text')"/>
      <CyButton
        theme="secondary"
        icon="add"
        @click="createWatchRule">
        {{ $t('createWatchRule') }}
      </CyButton>
    </div>

    <CyModal
      v-if="showDeleteDialog"
      :header-title="$t('confirmDeleteHeader')"
      :loading="deleting"
      :action-btn-func="onDeleteConfirm"
      :action-btn-disabled="deleting"
      :cancel-btn-func="() => $toggle.showDeleteDialog(false)"
      modal-type="delete"
      small>
      <p>
        {{ $t('forms.cannotBeUndone') }}
        <span v-html="$sanitizeHtml($tc('areYouSure', selectedRows.length, { item: selectedRows[0].name }))"/>
      </p>
      <ul
        v-if="selectedRows.length > 1"
        class="items-to-delete">
        <li
          v-for="{ canonical, name } of selectedRows"
          :key="canonical">
          <b>{{ name }}</b>
        </li>
      </ul>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CySearchBox from '@/components/search-box.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageUserWatchRules',
  components: {
    CySearchBox,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.userWatchRules'), [
      {
        label: this.$t('routes.profile'),
        name: 'profile',
      },
    ])
  },
  data: () => ({
    searchTerm: '',
    fetching: true,
    selectedRows: [],
    showDeleteDialog: false,
    deleting: false,
  }),
  computed: {
    ...mapState('notifications', [
      'watchRules',
    ]),
    ...mapState({
      organizations: (state) => state.organizations,
    }),
    $static () {
      return {
        tableHeaders: [
          {
            text: this.$t('headers.name'),
            value: 'name',
          },
          {
            text: this.$t('headers.status'),
            value: 'muted',
          },
          {
            text: this.$t('headers.project'),
            value: 'project_canonical',
          },
          {
            text: this.$t('headers.organization'),
            value: 'organization_canonical',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        actionMenuItems: (item) => [
          {
            label: 'Edit',
            icon: 'edit',
            action: () => this.$router.push({
              name: 'userWatchRule',
              params: { watchRuleCanonical: item.canonical },
            }),
          },
          {
            label: 'Mute',
            icon: 'volume_off',
            action: () => this.toggleWatchRuleMute(item, true),
          },
          {
            label: 'Unmute',
            icon: 'volume_up',
            action: () => this.toggleWatchRuleMute(item, false),
          },
          {
            divider: true,
          },
          {
            label: 'Delete',
            icon: 'delete',
            action: () => {
              this.selectedRows = [item]
              this.showDeleteDialog = true
            },
          },
        ],
      }
    },
    itemCountText () {
      const count = this.filteredItems.length
      const text = this.$tc('inventory.nItems', count, { n: count })
      return this.$sanitizeHtml(text)
    },
    filteredItems () {
      const sortedRules = _.sortBy(this.watchRules, 'name')

      if (!this.searchTerm) return sortedRules

      const search = this.searchTerm.toLowerCase()

      return sortedRules.filter((item) => {
        return item.name.toLowerCase().includes(search)
      })
    },
  },
  created () {
    this.fetchWatchRules()
  },
  methods: {
    ...mapActions('notifications', [
      'GET_WATCH_RULES',
      'UPDATE_WATCH_RULE',
    ]),
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    getOrgByCanonical (canonical) {
      return _.find(this.organizations, { canonical })
    },
    goToProject (projectCanonical) {
      const { orgCanonical } = this
      this.$router.push({
        name: 'project',
        params: {
          orgCanonical,
          projectCanonical,
        },
      })
    },
    async fetchWatchRules () {
      this.fetching = true
      await this.GET_WATCH_RULES()
      this.fetching = false
    },
    createWatchRule () {
      this.$router.push({ name: 'newUserWatchRule' })
    },
    getActionMenuItems (watchRule) {
      const excludeAction = watchRule.muted ? 'Mute' : 'Unmute'
      const items = this.$static.actionMenuItems(watchRule)
      return _.filter(items, ({ label }) => label !== excludeAction)
    },
    async toggleWatchRuleMute (watchRule, muted) {
      await this.UPDATE_WATCH_RULE({
        watchRuleCanonical: watchRule.canonical,
        watchRule: {
          ..._.pick(watchRule, ['name', 'filters']),
          muted,
        },
      })
      this.fetchWatchRules()
    },
    async onDeleteConfirm () {
      this.deleting = true
      const toDelete = this.selectedRows
      await this.BULK_DELETE({ keyPath: 'watchRules', toDelete, publicEndpoint: true })
      this.fetchWatchRules()
      this.selectedRows = []
      this.$toggle.showDeleteDialog(false)
      this.deleting = false
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.userWatchRules',
        areYouSure: 'Are you really sure you want to delete <b>{item}</b>? | Are you really sure you want to delete the following watch rules?',
        createWatchRule: 'Create watch rule',
        confirmDeleteHeader: 'Delete watch rules',
        deleteNWatchRules: 'Delete {n} watch rule | Delete {n} watch rules',
        emptyWatchRules: {
          title: `You're not watching anything yet`,
          text: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        },
        headers: {
          name: 'Rule name',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Watching',
          description: 'Watch rules help you stay in the loop by sending notifications whenever changes are made to a project or a specific event occurs.',
        },
        searchPlaceholder: 'Search items...',
        muted: 'Muted',
      },
      es: {
        title: '@:routes.userWatchRules',
        areYouSure: '¿Está realmente seguro de que desea eliminar <b>{item}</b>? | ¿Está realmente seguro de que desea eliminar las siguientes reglas de monitoreo?',
        createWatchRule: 'Crear regla de monitoreo',
        confirmDeleteHeader: 'Eliminar reglas de monitoreo',
        deleteNWatchRules: 'Eliminar {n} regla de monitoreo | Eliminar {n} reglas de monitoreo',
        emptyWatchRules: {
          title: `Aún no estás siguiendo nada`,
          text: 'Las reglas de seguimiento te ayudan a mantenerte informado enviándote notificaciones cada vez que se realizan cambios en un proyecto o se produce un evento específico.',
        },
        headers: {
          name: 'Nombre de la regla',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Siguiendo',
          description: 'Las reglas de seguimiento te ayudan a mantenerte informado enviándote notificaciones cada vez que se realizan cambios en un proyecto o se produce un evento específico.',
        },
        searchPlaceholder: 'Buscar elementos...',
        muted: 'Silenciado',
      },
      fr: {
        title: '@:routes.userWatchRules',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir supprimer <b>{item}</b> ? | Êtes-vous vraiment sûr de vouloir supprimer les règles de notification suivantes ?',
        createWatchRule: 'Créer une règle de notification',
        confirmDeleteHeader: 'Supprimer les règles de notification',
        deleteNWatchRules: 'Supprimer {n} règle de notification | Supprimer {n} règles de notification',
        emptyWatchRules: {
          title: `Vous n'avez encore aucune règle de notification`,
          text: `Les règles de notifications vous aident à rester informé en vous envoyant des notifications chaque fois que des modifications sont apportées à un projet ou qu'un événement spécifique se produit.`,
        },
        headers: {
          name: 'Nom de la règle',
          status: '@:forms.status',
          project: '@:Project',
          organization: '@:Organization',
        },
        page: {
          title: 'Suivi de notifications',
          description: `Les règles de notifications vous aident à rester informé en vous envoyant des notifications chaque fois que des modifications sont apportées à un projet ou qu'un événement spécifique se produit.`,
        },
        searchPlaceholder: 'Rechercher des éléments...',
        muted: 'Désactivée',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.user-watch-rules {
  width: 100%;

  ::v-deep {
    .v-data-table,
    .v-toolbar {
      background-color: transparent;
      color: cy-get-color("primary");
    }

    .v-data-table {
      .v-data-table-header th {
        color: cy-get-color("primary");
        font-size: $font-size-default;
        font-weight: $font-weight-bold;
      }
    }

    .v-toolbar__content {
      align-items: flex-start;
      padding: 4px 0;
    }
  }

  &__page {
    display: flex;
    justify-content: center;
  }

  &__count {
    margin-bottom: 16px;
    font-weight: $font-weight-default;
  }

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    padding: 32px 0;
    text-align: center;

    .v-icon {
      margin-bottom: 24px;
      color: cy-get-color("primary", "light-3");
    }

    .empty-watch-rules {
      &__title {
        margin-bottom: 8px;
        color: cy-get-color("primary");
        font-size: $font-size-lg;
        font-weight: 700;
      }

      &__text {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
