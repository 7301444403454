var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-watch-rules__page"},[(_vm.fetching)?_c('div',{staticClass:"user-watch-rules--loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","color":"secondary"}})],1):(_vm.watchRules.length)?_c('div',{staticClass:"user-watch-rules"},[_c('h3',{staticClass:"page-title",domProps:{"textContent":_vm._s(_vm.$t('page.title'))}}),_c('p',{staticClass:"page-description",domProps:{"textContent":_vm._s(_vm.$t('page.description'))}}),_c('v-data-table',{attrs:{"items":_vm.filteredItems,"headers":_vm.$static.tableHeaders,"checkbox-color":"secondary","disable-pagination":"","show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"height":"96","flat":""}},[_c('div',[_c('CySearchBox',{staticClass:"search-field",attrs:{"aria-label":"Search items","placeholder":_vm.$t('searchPlaceholder'),"append-icon":"search","clearable":""},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchTerm"}}),_c('h4',{staticClass:"user-watch-rules__count",domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.itemCountText))}})],1),_c('v-spacer'),(_vm.selectedRows.length)?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteNWatchRules', _vm.selectedRows.length, { n: _vm.selectedRows.length }))+" ")]):_c('CyButton',{attrs:{"theme":"secondary","icon":"add"},on:{"click":_vm.createWatchRule}},[_vm._v(" "+_vm._s(_vm.$t('createWatchRule'))+" ")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-2",attrs:{"item":{
              icon: item.project_canonical ? 'commit' : 'podcasts',
              color: item.project_canonical ? 'prod' : 'staging',
            },"sm":""}}),_c('strong',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.muted",fn:function(ref){
            var item = ref.item;
return [(item.muted)?_c('CyTag',{attrs:{"variant":"default","icon-before":"volume_off"}},[_vm._v(" "+_vm._s(_vm.$t('muted'))+" ")]):_vm._e()]}},{key:"item.project_canonical",fn:function(ref){
            var item = ref.item;
return [(item.project_canonical)?_c('span',{staticClass:"cy-link",on:{"click":function($event){return _vm.goToProject(item.project_canonical)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" folder_open ")]),_vm._v(" "+_vm._s(item.project_canonical)+" ")],1):_vm._e()]}},{key:"item.organization_canonical",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-2",attrs:{"item":_vm.getOrgByCanonical(item.organization_canonical),"sm":""}}),_vm._v(" "+_vm._s(_vm.getOrgByCanonical(item.organization_canonical).name)+" ")],1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('CyMenu',{attrs:{"items":_vm.getActionMenuItems(item),"min-width":280,"bottom":"","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"aria-label":"Actions menu","variant":"tertiary","theme":"primary","icon":"more_horiz","max-width":"24","max-height":"24","icon-only":"","sm":""},on:{"click":function($event){$event.stopPropagation();}}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1):_c('div',{staticClass:"user-watch-rules--empty"},[_c('v-icon',[_vm._v(" visibility ")]),_c('div',{staticClass:"empty-watch-rules__title",domProps:{"textContent":_vm._s(_vm.$t('emptyWatchRules.title'))}}),_c('div',{staticClass:"empty-watch-rules__text",domProps:{"textContent":_vm._s(_vm.$t('emptyWatchRules.text'))}}),_c('CyButton',{attrs:{"theme":"secondary","icon":"add"},on:{"click":_vm.createWatchRule}},[_vm._v(" "+_vm._s(_vm.$t('createWatchRule'))+" ")])],1),(_vm.showDeleteDialog)?_c('CyModal',{attrs:{"header-title":_vm.$t('confirmDeleteHeader'),"loading":_vm.deleting,"action-btn-func":_vm.onDeleteConfirm,"action-btn-disabled":_vm.deleting,"cancel-btn-func":function () { return _vm.$toggle.showDeleteDialog(false); },"modal-type":"delete","small":""}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitizeHtml(_vm.$tc('areYouSure', _vm.selectedRows.length, { item: _vm.selectedRows[0].name })))}})]),(_vm.selectedRows.length > 1)?_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.selectedRows),function(ref){
            var canonical = ref.canonical;
            var name = ref.name;
return _c('li',{key:canonical},[_c('b',[_vm._v(_vm._s(name))])])}),0):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }